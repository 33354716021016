import { Group, Text, Image } from "@mantine/core";
import applogo from "../../media/accTV.png";

import classes from "./Logo.module.css";
interface LogoProps {
  logo?: string;
  name?: string;
}
export default function Logo({logo = applogo, name = "ACC TV"}: LogoProps): React.JSX.Element {
  return (
    <Group className={classes.logo}>
      <Image radius="md" h={40} w={40} src={logo} />
      <Text fs="italic" size="xl">
        {name}
      </Text>
    </Group>
  );
}
